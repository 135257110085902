import { FEATURE } from '~/common/enums/feature.enum';
import { usePromoContests } from '~/domains/cms/PromoContests/usePromoContests';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

export const useHomePromoContests = () => {
  const isShowContestThumbnailEnabled = useIsFeatureEnabled(FEATURE.SHOW_CONTEST_ITEM_THUMBNAIL);

  const data = usePromoContests();
  return { data, isShowContestThumbnailEnabled };
};
