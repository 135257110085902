import { FEATURE } from '~/common/enums/feature.enum';
import useFeaturedContests from '~/domains/cms/FeaturedContests/useFeaturedContests';
import { useContests } from '~/domains/contest/hooks/useContests';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

export const useHomeFeaturedContests = () => {
  const isShowContestThumbnailEnabled = useIsFeatureEnabled(FEATURE.SHOW_CONTEST_ITEM_THUMBNAIL);

  const { data: contestIds } = useFeaturedContests();

  const contestIdsLength = contestIds?.length ?? 0;
  const hasFeaturedContests = contestIdsLength > 0;
  const data = useContests(
    {
      filter: { contestIds },
      limit: contestIdsLength,
      offset: 0,
    },
    { enabled: hasFeaturedContests, keepPreviousData: false }
  );

  return { data, isShowContestThumbnailEnabled };
};
