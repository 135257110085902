import { Box } from '@mantine/core';

import { useHome } from './hooks/useHome';
import useHomeStyles from './Home.styles';
import HomeFeaturedContests from './Featured';
import HomeContests from './Contests';
import HomeHeader from './Header';
import HomeFooter from './Footer';
import HomeMyEntriesSection from './components/HomeMyEntriesSection';

function Home() {
  const { classes, theme } = useHomeStyles();

  const { isCMSCarouselEnabled, isSignedIn } = useHome();

  return (
    <Box maw={theme.other.layout.maxWidth} m="0 auto">
      <Box component="section" className={classes.carousel}>
        <HomeHeader isCMSCarouselEnabled={isCMSCarouselEnabled} />

        <HomeFeaturedContests />
        {isSignedIn ? <HomeMyEntriesSection /> : null}
        <HomeContests />

        <HomeFooter />
      </Box>
    </Box>
  );
}

export default Home;
