import { Box, Flex, Skeleton, Stack } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import useIsUserAdmin from '~/domains/payments/hooks/useIsUserAdmin';
import { FEATURE } from '~/common/enums/feature.enum';
import ContestCard from '~/domains/contest/domains/common/components/ContestCard/ContestCard';
import type { GetPromoContestsItem } from '~/domains/cms/PromoContests/getPromoContests';

import { useContests } from '../../contest/hooks/useContests';

import HomeCarousel from './HomeCarousel';
import { HomeSectionTitle, HomeViewAllLink } from './sectionComponents';

function HomeContest({
  contestIds,
  title,
  viewLink,
  isShowContestThumbnailEnabled,
}: GetPromoContestsItem & {
  isShowContestThumbnailEnabled: boolean;
}) {
  const { t } = useTranslation('home');

  const isAdmin = useIsUserAdmin();
  const isCountdownPillEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CONTEST_COUNTDOWN_TIMER);

  const {
    data: contests,
    isLoading,
    isInitialLoading,
  } = useContests(
    {
      filter: { contestIds },
      limit: contestIds?.length,
      includeFull: isAdmin,
      offset: 0,
    },
    { enabled: contestIds?.length > 0 }
  );
  const homeContests = contests?.data;

  if (!contestIds?.length) return null;

  if (isLoading && isInitialLoading)
    return (
      <Stack spacing="lg">
        <Skeleton h={28} w={230} />
        <Skeleton mih={170} />
      </Stack>
    );

  if (homeContests?.length === 0) return null;

  return (
    <Stack key={title} spacing={0}>
      <Flex justify="space-between" w="100%" align="baseline">
        <HomeSectionTitle>{title}</HomeSectionTitle>
        <HomeViewAllLink href={viewLink} />
      </Flex>
      <Box p={0} component="ul" mt={0} w="100%">
        <HomeCarousel>
          {homeContests?.map((contest, index) => (
            <HomeCarousel.Slide key={contest.id}>
              <ContestCard
                contest={contest}
                isContestThumbnailShown={isShowContestThumbnailEnabled}
                isNotAllowedInLocation={contest.is_allowed_in_location === false}
                isBadgeIconSectionEnabled={false}
                isBannerEnabled
                isCountdownPillEnabled={isCountdownPillEnabled}
                promoSource={`home_contests_${title}`}
                promoIndex={index + 1}
              />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
    </Stack>
  );
}

export default HomeContest;
