/* eslint-disable no-underscore-dangle */
import type { StrapiResponse } from '@betterpool/strapi-client';
import { parseStrapiResponse } from '@betterpool/strapi-client';
import { validate as validateUuid } from 'uuid';

import { fetchStrapiJSON } from '../fetchStrapiJSON';
import { StrapiCollection } from '../enums/strapi.enum';

type GetPromoContestsItem = {
  title: string;
  contestIds: string[];
  order: number;
  viewLink: string;
  location?: string[];
};

type GetPromoContestsResponse = StrapiResponse<GetPromoContestsItem>;

const parseResponse = (response: GetPromoContestsResponse, country?: string) => {
  const parsedStrapiResponse = parseStrapiResponse(response);

  return parsedStrapiResponse
    .filter((d) => {
      if (!country || !d.__attributes.location?.length) return true;

      return d.__attributes.location.map((c) => c.toLowerCase()).includes(country.toLowerCase());
    })
    .sort((a, b) => a.__attributes.order - b.__attributes.order)
    .map(({ __attributes }) => ({
      contestIds: __attributes.contestIds.filter((contestId) => validateUuid(contestId)),
      title: __attributes.title,
      order: __attributes.order,
      viewLink: __attributes.viewLink,
    }));
};

const getPromoContests = (country?: string) =>
  fetchStrapiJSON<GetPromoContestsResponse>(StrapiCollection.HOME_PROMO_CUSTOM_CONTESTS, {}).then(
    (r) => parseResponse(r, country)
  );

export default getPromoContests;

export type { GetPromoContestsItem };
